import React from 'react'
import {Create, SimpleForm, TextInput, SelectInput, ReferenceInput, AutocompleteInput, DateInput,} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="place"/>
      <TextInput source="email"/>
      <TextInput source="phone"/>
      <TextInput source="url"/>
      <DateInput source="startDate"/>
      <DateInput source="endDate"/>
      <SelectInput source="type" choices={[
        {id: '국제학술', name: '국제학술'},
        {id: '국내학술', name: '국내학술'}
      ]}/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="groupId" reference="groups">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
