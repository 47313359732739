import KeywordList from './KeywordList'
import KeywordShow from './KeywordShow'
import icon from '@material-ui/icons/Toc'
import KeywordCreate from "./KeywordCreate"
import KeywordEdit from "./KeywordEdit"

export default {
  create: KeywordCreate,
  edit: KeywordEdit,
  show: KeywordShow,
  list: KeywordList,
  icon: icon,

}
