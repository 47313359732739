import React from 'react'
import {DateField, ReferenceField, SelectField, Show, Tab, TabbedShowLayout, TextField,UrlField} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";

export default (props) => (
  <Show {...props} actions={<ShowActions/>}>

    <TabbedShowLayout>
      <Tab label="기본 정보">
        <TextField source='id'/>
        <TextField source="name"/>
        <TextField source="place"/>
        <TextField source="email"/>
        <TextField source="phone"/>
        <UrlField source="url" target="_blank"/>
        <DateField source="startDate"/>
        <DateField source="endDate"/>
        <SelectField source="type" choices={[
          {id: '국제학술', name: '국제학술'},
          {id: '국내학술', name: '국내학술'}
        ]}/>
        <ReferenceField source="groupId" reference="groups" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="keywordId" reference="keywords" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="adminId" reference="admins" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <DateField source="createdAt" showTime/>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
