import React, {useState} from 'react'
import CreateActions from '../../custom/common/CreateActions'
import BibliographyToolbar from './BibliographyToolbar'
import {required} from '../../validators'
import * as ndsl from './choices/ndsl'
import * as kolis from './choices/kolis'
import * as kci from './choices/kci'
import {
  AutocompleteArrayInput,
  Create,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  maxLength,
  minLength,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import AutocompleteInput from 'ra-ui-materialui/lib/input/AutocompleteInput'
import {useForm} from 'react-final-form'

export default (props) => {
  const [source, setSource] = useState(null)
  const [data, setData] = useState({})
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm toolbar={<BibliographyToolbar {...props} data={data} history={props.history}/>}>
        <SelectInput source="source" choices={[
          {id: 'cau', name: 'CAU'},
          {id: 'dblp', name: 'DBLP'},
          {id: 'ndsl', name: 'NDSL'},
          {id: 'kolis', name: 'KOLIS'},
          {id: 'kci', name: 'KCI'},
          {id: 'manual', name: '개별 등록'}
        ]}/>
        <FormDataConsumer>
          {({formData, ...rest}) => {
            const form = useForm()
            if (source !== formData.source && source !== null) {
              form.reset()
              setSource(formData.source)
            }
            setData(formData)
            if (formData.hasOwnProperty('source')) {
              switch (formData.source) {
                case 'cau':
                  return (
                    <FileInput source="files" accept=".xlsx" validate={required}>
                      <FileField source="src" title="title"/>
                    </FileInput>
                  )
                case 'dblp':
                  return (
                    <FileInput source="files" accept=".xml">
                      <FileField source="src" title="title"/>
                    </FileInput>
                  )
                case 'ndsl':
                  return (
                    <>
                      <div><SelectInput source="target" label="검색대상 콘텐츠" choices={ndsl.target} validate={required}
                                        defaultValue="ARTI"/></div>
                      <div><SelectInput source="searchField" label="검색항목" choices={ndsl.searchField}
                                        validate={required} defaultValue="BI"/></div>
                      <div><SelectInput source="displayCount" label="검색결과 출력건수" choices={ndsl.displayCount}
                                        validate={required} defaultValue="10"/></div>
                      <div><NumberInput source="startPosition" label="검색의 시작위치"
                                        validate={required} defaultValue="1"/></div>
                      <div><SelectInput source="sortBy" label="정렬항목" choices={ndsl.sortBy} defaultValue="pubYear"/>
                      </div>
                      <TextInput source="query" label="검색 질의어"/>
                    </>)
                case 'kolis':
                  return (
                    <>
                      <div><SelectInput source="search_field1" label="검색항목" choices={kolis.searchField}
                                        validate={required} defaultValue="total_field"/></div>
                      <div><SelectInput source="page" label="검색의 시작위치" choices={kolis.displayCount}
                                        validate={required} defaultValue="1"/></div>
                      <div><SelectInput source="per_page" label="쪽당출력건수" choices={kolis.displayCount}
                                        validate={required} defaultValue="100"/></div>
                      <div><SelectInput source="sort_ksj" label="정렬항목" choices={kolis.sortItem}
                                        defaultValue="SORT_TITLE"/></div>
                      <div><SelectInput source="sort" label="정렬방식" choices={kolis.sortBy} defaultValue="ASC"/></div>
                      <TextInput source="value1" label="검색 질의어" validate={required}/>
                    </>)
                case 'kci':
                  return (
                    <>
                      <div><TextInput source="title" label="제목"/></div>
                      {/*<div><TextInput source="keyword" label="키워드"/></div>*/}
                      <div><TextInput source="author" label="저자"/></div>
                      <div><TextInput source="journal" label="저널"/></div>
                      <div><TextInput source="dateFrom" label="발행년월 시작(6자리)"
                                      validate={[minLength(6), maxLength(6)]}/></div>
                      <div><TextInput source="dateTo" label="발행년월 (6자리)" validate={[minLength(6), maxLength(6)]}/>
                      </div>
                      <div><SelectInput source="displayCount" label="출력건수" validate={[required]}
                                        choices={kci.displayCount} defaultValue={100}/></div>
                      <div><SelectInput source="sortNm" label="정렬방식" choices={kci.sortNm}/></div>
                      {formData.hasOwnProperty('sortNm') &&
                      <div><SelectInput source="sortDir" label="정렬방식" choices={kci.sortDir} defaultValue='asc'/>
                      </div>}
                    </>)
                case 'manual':
                  return (
                    <>
                      <div><TextInput source="title" label="제목" validate={required}/></div>
                      <div><TextInput source="key" label="키" validate={required}/></div>
                      <div><TextInput source="url" label="url"/></div>
                      <div><TextInput target="_blank" source="ee"/></div>

                      <SelectInput source="type" choices={[
                        {id: '학술', name: '학술'},
                        {id: '미디어', name: '미디어'},
                        {id: '출판', name: '출판'},
                      ]} label='타입'/>
                      <ReferenceArrayInput filterToQuery={(searchText) => ({search: searchText})}
                                           resource='bibliography' source="authorIds" reference="authors" label='저자'>
                        <AutocompleteArrayInput optionText="name"/>
                      </ReferenceArrayInput>
                      <ReferenceArrayInput filterToQuery={(searchText) => ({search: searchText})}
                                           resource='bibliography' source="journalIds" reference="journals" label='저널'>
                        <AutocompleteArrayInput optionText="name"/>
                      </ReferenceArrayInput>
                      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                                      source="keywordId" reference="keywords">
                        <AutocompleteInput optionText="name"/>
                      </ReferenceInput>
                      <div><DateInput source="postedAt" validate={required} label='출간일'/></div>

                    </>)
                default:
                  break
              }
            }
          }
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
