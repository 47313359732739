import ArticleList from './ArticleList'
import ArticleShow from './ArticleShow'
import icon from '@material-ui/icons/Toc'
import ArticleCreate from "./ArticleCreate"
import ArticleEdit from "./ArticleEdit"

export default {
  create: ArticleCreate,
  edit: ArticleEdit,
  show: ArticleShow,
  list: ArticleList,
  icon: icon,

}
