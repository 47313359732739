import React from 'react'
import {Toolbar,} from 'react-admin'
import {CustomSaveButton} from "./CustomSaveButton"

export default ((props) => {
  return (
      <Toolbar {...props}>
        <CustomSaveButton {...props} data={props.data} history={props.history}/>
      </Toolbar>
  )
})

