import React from 'react'
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	DateInput,
	Edit,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
	<Edit {...props} actions={<EditActions/>}>
		<SimpleForm toolbar={<EditToolbar/>}>
			<TextInput source="name" validate={required}/>
			<TextInput source="place"/>
			<TextInput source="phone"/>
			<TextInput source="email"/>
			<TextInput source="url"/>
			<SelectInput source="type" choices={[
				{id: '국제저서', name: '국제저서'},
				{id: '국내저서', name: '국내저서'}
			]}/>
			<ReferenceInput source="groupId" reference="groups">
				<SelectInput optionText="name"/>
			</ReferenceInput>
			<ReferenceArrayInput resource='books' source="authorIds" reference="authors" label='저자'>
				<AutocompleteArrayInput optionText="name"/>
			</ReferenceArrayInput>
			<ReferenceInput source="keywordId" reference="keywords">
				<AutocompleteInput optionText="name"/>
			</ReferenceInput>
			<DateInput source="publishedAt" showTime />
		</SimpleForm>
	</Edit>
)
