import axios from 'axios'

const models = {
  auth: require('./auth'),
  admins: require('./admins'),
  articles: require('./articles'),
  groups: require('./groups'),
  conferences: require('./conferences'),
  books: require('./books'),
  bibliographies: require('./bibliographies'),
  authors: require('./authors'),
  imports: require('./imports'),
  keywords: require('./keywords'),
  journals: require('./journals'),
  totalSearch: require('./totalSearch'),
}

async function refreshToken(apiUrl) {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (accessToken && refreshToken) {
      const options = models.auth.request(apiUrl, 'REFRESH', {data: {accessToken, refreshToken}})
      const {data} = await axios(options)
      return data.accessToken
    }
  } catch (e) {
    throw e
  }
}

export default function (apiUrl) {
  const httpClient = (options = {}) => {
    if (!options.headers) options.headers = {}
    const token = localStorage.getItem('accessToken')
    if (token) options.headers.authorization = `Bearer ${token}`
    return axios(options)
  }

  return async function (type, resource, params) {
    const resourceModel = models[resource]
    const options = await resourceModel.request(apiUrl, type, params)
    try {
      const response = await httpClient(options)
      return resourceModel.response(response, type, params)
    } catch (e) {
      console.log(e)
      if (e && e.response && e.response.status === 401) {
        console.log('IF 401 ERROR')
        try {
          const accessToken = await refreshToken(apiUrl, e)
          if (accessToken) {
            localStorage.setItem('accessToken', accessToken)
            const response = await httpClient(options)

            return resourceModel.response(response, type, params)
          }else{
            console.log('액세스 토큰 없음')
            window.localStorage.clear()
            window.location.reload()
          }
        } catch (e) {
          console.log('토큰 연장 실패')
          window.localStorage.clear()
          window.location.reload()
          throw e.response
        }
      }
    }
  }
}
