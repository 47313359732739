export const target =  [
  {id: 'ARTI', name: '논문전체'},
  {id: 'NART', name: '학위논문을 제외한 논문전체'},
  {id: 'JAKO', name: '국내논문'},
  {id: 'JAFO', name: '해외논문'},
  {id: 'CFKO', name: '국내 회의자료'},
  {id: 'CFFO', name: '해외 회의자료'},
  {id: 'DIKO', name: '국내 학위논문'},
]

export const searchField = [
  {id: 'BI', name: '통합검색[제목 or 저자 or 발행기관 or 키워드 or 초록]'},
  {id: 'TI', name: '제목'},
  {id: 'AB', name: '초록'},
  {id: 'PB', name: '발행기관'},
  {id: 'AU', name: '저자명'},
  {id: 'SO', name: '저널명'},
  {id: 'SN', name: 'ISSN'},
  {id: 'BN', name: 'ISBN'},
  {id: 'KW', name: '키워드'},
  {id: 'PY', name: '발행년도'},
  {id: 'LA', name: '발행언어'},
  {id: 'JO', name: '저널번호(게재지 번호)'},
  {id: 'SU', name: 'DDC분류'},
  {id: 'KU', name: '과학기술표준분류'},
  {id: 'AO', name: '저자소속기관'},
]
export const displayCount= [
  {id: '10', name: '10'},
  {id: '20', name: '20'},
  {id: '50', name: '50'},
  {id: '100', name: '100'},
]

export const sortBy= [
  {id: 'pubYear', name: '발행일'},
  {id: 'title', name: '논문명'},
  {id: 'jtitle', name: '저널명'},
  {id: '', name: '정확도'},
]