import React from 'react'
import {
	ChipField,
	DateField,
	ReferenceArrayField,
	ReferenceField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	UrlField,
} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";

export default (props) => (
	<Show {...props} actions={<ShowActions/>}>
		<SimpleShowLayout>
			<TextField source='id'/>
			<TextField source="source"/>
			<TextField source="key"/>
			<TextField source="author"/>
			<TextField source="title"/>
			<TextField source="volume"/>
			<TextField source="year"/>
			<TextField source="month"/>
			<UrlField target="_blank" source="url"/>
			<UrlField target="_blank" source="ee"/>
			<TextField source="type"/>
			<TextField source="pages"/>
			<ReferenceField source="keywordId" reference="keywords" label='키워드' allowEmpty link={'show'}>
				<TextField source="name"/>
			</ReferenceField>
			<ReferenceField source="adminId" reference="admins" allowEmpty link={'show'}>
				<TextField source="name"/>
			</ReferenceField>
			<ReferenceArrayField label='저자' reference="authors" source="authorIds" allowEmpty>
				<SingleFieldList linkType='show'>
					<ChipField source="name"/>
				</SingleFieldList>
			</ReferenceArrayField>

			<ReferenceArrayField label='저널' reference="journals" source="journalIds" allowEmpty>
				<SingleFieldList linkType='show'>
					<ChipField source="name"/>
				</SingleFieldList>
			</ReferenceArrayField>
			<TextField source="postedAt"/>
			<DateField source="createdAt" showTime/>
		</SimpleShowLayout>
	</Show>
)
