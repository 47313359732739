import React from 'react'
import {ArrayField, DateField, ReferenceField, Show, Tab, TabbedShowLayout, TextField, UrlField} from 'react-admin'
import Datagrid from "ra-ui-materialui/lib/list/Datagrid"
import {useHistory} from "react-router-dom"
import ShowActions from "../../custom/common/ShowActions";

export default (props) => {

  const history = useHistory()

  return (
    <Show {...props} actions={<ShowActions/>}>

      <TabbedShowLayout>
        <Tab label="기본 정보">
          <TextField source='id'/>
          <TextField source="name"/>
          <TextField source="type"/>
          <TextField source="place"/>
          <UrlField source="url" target="_blank"/>
          <ReferenceField source="groupId" reference="groups" allowEmpty link={'show'}>
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField source="keywordId" reference="keywords" allowEmpty link={'show'}>
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField source="adminId" reference="admins" allowEmpty link={'show'}>
            <TextField source="name"/>
          </ReferenceField>
          {/*<TextField source="total"/>*/}
          <DateField source="createdAt" showTime locales='ko'/>
        </Tab>
        <Tab label="논문 정보">
          <ArrayField source="data">
            <Datagrid rowClick={(record, resource, row) => history.push(`/bibliographies/${row.id}/show`)}>
              <TextField source='id' sortable={false}/>
              <TextField source="source" sortable={false}/>
              <TextField source="key" sortable={false}/>
              <TextField source="title" sortable={false}/>
              <TextField source="volume" sortable={false}/>
              <TextField source="year" sortable={false}/>
              <TextField source="month" sortable={false}/>
              <UrlField target="_blank" source="ee" sortable={false}/>
              <UrlField target="_blank" source="url" sortable={false}/>
              <DateField source="postedAt" sortable={false}/>
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}