import React from 'react'
import {DateField, SelectField, Show, SimpleShowLayout, TextField} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";


export default (props) => (
  <Show {...props} actions={<ShowActions/>}>
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="name"/>
      <SelectField source="role" choices={[
        {id: 'master', name: '관리자'},
        {id: 'manager', name: '작업자'},
        {id: 'guest', name: '손님'}
      ]}/>
      <DateField source="createdAt" locales='ko' showTime/>
    </SimpleShowLayout>
  </Show>
)

