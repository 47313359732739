import React from 'react'
import {connect} from 'react-redux'
import {getResources, MenuItemLink, translate, DashboardMenuItem} from 'react-admin'
import {withRouter} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import {withStyles} from '@material-ui/core/styles'
import TocIcon from '@material-ui/icons/Toc'

const styles = theme => ({
  root: {
    width: '100%',
    margin: 0
  },
  heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
})

const menus = [
  {
    title: '통합 검색',
    items: ['totalSearch']
  },
  {
    title: '서지 관리',
    items: ['bibliographies']
  },
  {
    title: '서지 외의 관리',
    items: ['groups','authors','books','journals','articles','conferences',]
  },
  {
    title: '키워드 관리',
    items: ['keywords']
  },
  {
    title: '관리자 관리',
    items: ['admins']
  },
]


const Menu = withStyles(styles)(({resources, classes, onMenuClick, logout, translate, location}) => {
  return menus.map((menu, index) => {
    let items = null
    if (menu === 'dashboard') {
      return <DashboardMenuItem onClick={onMenuClick}/>
    } else {
      items = menu.items.map((item, index) => {
        const resource = resources.find(resource => resource.name === item)
        if (resource) {
          return <MenuItemLink
              key={index}
              to={`/${resource.name}`}
              leftIcon={<TocIcon/>}
              primaryText={translate(`resources.${resource.name}.name`)}
              onClick={onMenuClick}
          />
        }
        return <MenuItemLink
            disabled
            key={index}
            to={`/`}
            leftIcon={<TocIcon/>}
            primaryText={item + ' (개발)'}
            onClick={onMenuClick}
        />
      })
      if(menu.items[0] === 'admins' && localStorage.getItem('role') === 'guest') return null
      let defaultExpanded = false
      if (menu.items.find(item => location.pathname.indexOf(item) > 0)) defaultExpanded = true

      return <ExpansionPanel key={index} defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography className={classes.heading}>{menu.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>{items}</ExpansionPanelDetails>
      </ExpansionPanel>
    }
  })
})

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(translate(Menu)))
