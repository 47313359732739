import React from 'react'
import {
  ChipField,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField
} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";

export default (props) => (
  <Show {...props} actions={<ShowActions/>}>

    <TabbedShowLayout>
      <Tab label="기본 정보">
        <TextField source='id'/>
        <TextField source="name"/>
        <TextField source="place"/>
        <TextField source="type"/>
        <TextField source="email"/>
        <TextField source="phone"/>
        <UrlField source="url" target="_blank"/>
        <ReferenceField source="groupId" reference="groups" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="keywordId" reference="keywords" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="adminId" reference="admins" allowEmpty link={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceArrayField label='저자' reference="authors" source="authorIds" allowEmpty link={'show'}>
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="createdAt" showTime/>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
