import {CreateButton, TopToolbar} from "react-admin";
import React from "react";

const ListActions = props => {
  const permission = localStorage.getItem('role')
  return (
    <TopToolbar>
      {permission !== 'guest' && <CreateButton label={'추가'} basePath={props.basePath} data={props.data}/>}
    </TopToolbar>
  )
}
export default ListActions