import React from 'react'
import {
  CreateButton,
  Datagrid,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin'
import AutocompleteInput from 'ra-ui-materialui/lib/input/AutocompleteInput'

const Actions = props => {
  const permission = localStorage.getItem('role')
  return (
    <TopToolbar>
      {permission !== 'guest' && <CreateButton label={'서지 추가'} basePath={props.basePath} data={props.data}/>}
    </TopToolbar>
  )
}
const ListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="서지 제목" source="search" alwaysOn/>
      <SelectInput source="source" allowEmpty emptyText={'전체'} choices={[
        {id: 'cau', name: 'CAU'},
        {id: 'dblp', name: 'DBLP'},
        {id: 'ndsl', name: 'NDSL'},
        {id: 'kolis', name: 'KOLIS'},
        {id: 'kci', name: 'KCI'},
      ]} alwaysOn/>
      <ReferenceInput source="keywordId"
                      reference="keywords"
                      label='분류 검색'
                      allowEmpty alwaysOn
                      emptyValue=''
                      emptyText='전체'>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </Filter>
  )
}
export default (props) => {
  return (
    <List {...props}
          exporter={false}
          perPage={25}
          filters={<ListFilter/>}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source='id' sortable={true}/>
        <TextField source="source" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="postedAt" sortable={true}/>
        {/*<TextField source="keyword" sortable={false}/>*/}
      </Datagrid>
    </List>
  )
}
