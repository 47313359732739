import React from 'react'
import {Edit, ReferenceInput, AutocompleteInput, SimpleForm, TextInput, DateInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput source='title' validate={required}/>
      <TextInput source="url"/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="groupId" reference="groups">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="authorId" reference="authors">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <DateInput source="postedAt"/>
    </SimpleForm>
  </Edit>
)
