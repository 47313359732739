import {CREATE, DELETE, GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE} from 'react-admin'
import {isNil, omitBy} from "lodash"

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/bibliographies'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      if (params.data.source === 'ndsl') url = apiUrl + '/imports/ndsl'
      if (params.data.source === 'kolis') url = apiUrl + '/imports/kolis'
      if (params.data.source === 'kci') url = apiUrl + '/imports/kci'
      break
    case 'DBLP':
      url += `/dblp`
      options.method = 'POST'
      options.data = {bibliographies: params.data.dblp}

      break
    case 'CAU':
      url += `/CAU`
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case GET_MANY_REFERENCE: {
      const {page, perPage} = params.pagination
      const {order} = params.sort
      options.params = {
        ...params.filter,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
        lastEvaluatedKey: params.lastEvaluatedKey
      }
      options.params[params.target] = params.id
      break
    }

    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      delete params.data.createdAt
      options.data = omitBy(params.data, isNil)
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}

  const {data, status} = res
  switch (type) {
    case GET_LIST:
      if (data) {
        res.data.data = res.data.data.map((item) => {
          if (item.author) item.author = item.author.toString()
          return item
        })
        ret = res.data
      }
      break
    case GET_ONE:
      if (data) {
        ret = {data: res.data}
      }
      break
    case DELETE:
      if (status === 204) ret = {data: {id: params.id}}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}



