import React from 'react'
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin'
import ListActions from "../../custom/common/ListActions";

const ListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="저자 검색" source="search" alwaysOn/>
      <TextInput label="장소 검색" source="place" alwaysOn/>
      <TextInput label="타입 검색" source="type" alwaysOn/>
    </Filter>
  )
}

export default (props) => {
  return (
    <List {...props}
          exporter={false}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true}/>
        <TextField source="name" sortable={false}/>
        <TextField source="personId" sortable={false}/>
        <TextField source="type" sortable={false}/>
        <TextField source="place" sortable={false}/>
        <TextField source="bibliographyCount" sortable={true}/>
      </Datagrid>
    </List>
  )
}
