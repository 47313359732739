import React from 'react'
import {Edit, SelectInput, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
	<Edit {...props} actions={<EditActions/>}>
		<SimpleForm toolbar={<EditToolbar/>}>
			<TextInput source="name" validate={required}/>
			<SelectInput source="role" choices={[
				{id: 'master', name: '관리자'},
				{id: 'manager', name: '작업자'},
				{id: 'guest', name: '손님'}
			]} validate={required}/>
			<TextInput source="password" type='password' validate={required}/>
		</SimpleForm>
	</Edit>
)
