const parseString = require('xml2js').parseString;

export default async props => {
  let dblpString = ""
  const test = await asyncXML(props.data.files.src)
  test.forEach((value) => {
    const string = new TextDecoder("utf-8").decode(value);
    dblpString = dblpString + string
  })
  let data = []
  parseString(dblpString, (err, result) => {
    console.log(err, result)
    if(result.dblp.hasOwnProperty('article')) data.push(result.dblp.article)
    if(result.dblp.hasOwnProperty('book')) data.push(result.dblp.book)
    if(result.dblp.hasOwnProperty('proceedings')) data.push(result.dblp.proceedings)
    if(result.dblp.hasOwnProperty('inproceedings')) data.push(result.dblp.inproceedings)
    if(result.dblp.hasOwnProperty('www')) data.push(result.dblp.www)
    if(result.dblp.hasOwnProperty('mastersthesis')) data.push(result.dblp.mastersthesis)
    if(result.dblp.hasOwnProperty('incollection')) data.push(result.dblp.incollection)
    if(result.dblp.hasOwnProperty('phdthesis')) data.push(result.dblp.phdthesis)
    data = data.flat()
  })
  console.log(data)
  return data.map((item, index) => {
    const object = {}
    object.source = 'dblp'
    if (item.$.hasOwnProperty('key')) object.key = item.$.key
    if (item.$.hasOwnProperty('mdate')) object.postedAt = item.$.mdate

    if (item.hasOwnProperty('author')) {
      const author = item.author.map((author) => {
        if (author.hasOwnProperty('_')) {
          return author['_']
        } else return author
      })
      object.author = author
    }

    if (item.hasOwnProperty('publisher')) object.author = item.publisher[0]


    if (item.hasOwnProperty('title')) {
      const title = item.title.map((title) => {
        if (title.hasOwnProperty('_')) {
          return title['_']
        } else return title
      })
      object.title = title[0]
    }
    if (item.hasOwnProperty('volume')) object.volume = item.volume[0]
    if (item.hasOwnProperty('year')) object.year = parseInt(item.year[0])
    if (item.hasOwnProperty('journal')) object.journal = item.journal[0]
    if (item.hasOwnProperty('url')) object.url = item.url[0]
    if (item.hasOwnProperty('pages')) object.pages = item.pages[0]

    if (item.hasOwnProperty('ee')) {
      const ee = item.ee.map((ee) => {
        if (ee.hasOwnProperty('_')) {
          return ee['_']
        } else return ee
      })
      object.ee = ee[0]
    }

    if (item.hasOwnProperty('month')) {
      const month = item.month[0].toLowerCase();
      const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
      object.month = months.indexOf(month) + 1;
    }
    return object
  })
}

const asyncXML = (xml) => {
  return new Promise((resolve, reject) => {
    let result = [];
    (async () => {
      const fetchedResource = await fetch(xml)
      const reader = await fetchedResource.body.getReader()

      reader.read().then(function processText({done, value}) {
        if (done) {
          console.log('Stream finished. Content received:')
          resolve(result)
          return
        }
        result.push(value)

        return reader.read().then(processText)
      })
    })()
  })
}