import React from 'react'
import {createBrowserHistory} from "history"
import {Admin, Resource} from 'react-admin'
import authProvider from './providers/authProvider'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'
import bibliographies from './components/bibliographies'
import admins from './components/admins'
import journals from './components/journals'
import authors from './components/authors'
import books from './components/books'
import groups from './components/groups'
import conferences from './components/conferences'
import articles from './components/articles'
import totalSearch from './components/totalSearch'
import keywords from './components/keywords'
import Auth from './custom/auth'
import Layout from "./custom/Layout"

export const history = createBrowserHistory()
export const dataProvider = _dataProvider(process.env.REACT_APP_BASE_URL)

const App = () => <Admin
    title={process.env.NODE_ENV === 'production' ? 'index 관리자' : 'index 관리자 - DEV'}
    loginPage={Auth}
    appLayout = {Layout}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    history={history}>
  {(permissions) => {
    const resources = []
    resources.push( <Resource name='bibliographies' {...bibliographies} />,
      <Resource name='books' {...books} />,
      <Resource name='totalSearch' {...totalSearch} />,
      <Resource name='authors' {...authors} />,
      <Resource name='groups' {...groups} />,
      <Resource name='keywords' {...keywords} />,
      <Resource name='conferences' {...conferences} />,
      <Resource name='journals' {...journals} />,
      <Resource name='articles' {...articles} />,
      <Resource name='imports' />,)
    if (permissions === 'manager' || permissions === 'master') {
      resources.push(
        <Resource name='admins' {...admins} />,
      )
    }

    return resources
  }}

</Admin>

export default App