export const searchField = [
  {id: 'total_field', name: '전체'},
  {id: 'title', name: '제목'},
  {id: 'author', name: '저자'},
  {id: 'publisher', name: '발행자/대학명'},
]
export const displayCount= [
  {id: '1', name: '1'},
  {id: '10', name: '10'},
  {id: '20', name: '20'},
  {id: '50', name: '50'},
  {id: '100', name: '100'},
]

export const sortBy= [
  {id: 'ASC', name: '오름차순'},
  {id: 'DESC', name: '내림차순'},
]

export const sortItem= [
  {id: 'SORT_TITLE', name: '표제'},
  {id: 'SORT_PUBLISHER', name: '저자'},
  {id: 'PUB_YEAR_INFO', name: '발행년'},
]