import Button from '@material-ui/core/Button'
import React, {useState} from 'react'
import {CREATE} from 'react-admin'
import {dataProvider} from '../../App'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress'
import dblpHandler from './dataHandler/dblpHandler'
import cauHandler from './dataHandler/cauHandler'
import CircularProgress from '@material-ui/core/CircularProgress'

export const CustomSaveButton = props => {
  const [showDialog, setShowDialog] = useState(false)
  const [total, setTotal] = useState(0)
  const [progress, setProgress] = useState(0)
  const [circular, setCircular] = useState(false)
  const handleCloseClick = () => {
    props.history.push('/bibliographies')
  }
  const handleData = async () => {
    let bibliographies = []
    console.log(props.data.source)
    switch (props.data.source) {

      case 'cau':
        setShowDialog(true)
        const handleData = await cauHandler(props)
        const resCon = await dataProvider('CAU', 'bibliographies', {data: handleData})

        console.log(resCon)
        props.history.push('/bibliographies')
        break

      case 'manual':
        // console.log(props.handleSubmit())
        // const resManual = await dataProvider(CREATE, 'bibliographies', {data: props.data})
        // console.log(resManual)
        props.handleSubmitWithRedirect('/bibliographies')
        break

      case 'dblp':
        setShowDialog(true)
        bibliographies = await dblpHandler(props)
        setTotal(bibliographies.length)
        for (let i = 0; i < bibliographies.length / 100; i++) {
          setProgress(prevCount => prevCount + 100)
          const resDBLP = await dataProvider('DBLP', 'bibliographies', {
            data: {
              dblp: bibliographies.slice(i * 100, i * 100 + 100)
              , source: 'dblp'
            }
          })
        }
        props.history.push('/bibliographies')
        break

      case'ndsl':
        setCircular(true)
        await dataProvider('NDSL', 'imports', {data: props.data})
        props.history.push('/bibliographies')
        // const array = ['Artificial Intelligence', 'AI','Neural Network', 'Deep Learning', 'Machine Learning']
        // for (let i = 0; i < array.length; i++) {
        //   props.data.query = array[i]
        //   props.data.startPosition = 100
        //   await dataProvider('NDSL', 'imports', {data: props.data})
        //   props.data.startPosition = 200
        //   await dataProvider('NDSL', 'imports', {data: props.data})
        //   props.data.startPosition = 300
        //   await dataProvider('NDSL', 'imports', {data: props.data})
        //   props.data.startPosition = 400
        //   await dataProvider('NDSL', 'imports', {data: props.data})
        //   props.data.startPosition = 500
        //   await dataProvider('NDSL', 'imports', {data: props.data})
        // }
        break
      case'kolis':
        setCircular(true)
        await dataProvider('KOLIS', 'imports', {data: props.data})
        // const array = ['AI','Neural Network', 'Deep Learning', 'Machine Learning']
        // for(let i = 0 ; i < array.length ; i++){
        //   props.data.value1 = array[i]
        //   props.data.page=1
        //   await dataProvider('KOLIS', 'imports', {data: props.data})
        //   props.data.page=2
        //   await dataProvider('KOLIS', 'imports', {data: props.data})
        //   props.data.page=3
        //   await dataProvider('KOLIS', 'imports', {data: props.data})
        //   props.data.page=4
        //   await dataProvider('KOLIS', 'imports', {data: props.data})
        //   props.data.page=5
        //   await dataProvider('KOLIS', 'imports', {data: props.data})
        //
        // }
        props.history.push('/bibliographies')
        break
      case'kci':
        setCircular(true)
        await dataProvider('KCI', 'imports', {data: props.data})
        // const array3 = ['Artificial Intelligence', 'AI','Neural Network', 'Deep Learning', 'Machine Learning']
        // for (let i = 0; i < array3.length; i++) {
        //   props.data.title = array3[i]
        //   await dataProvider('KCI', 'imports', {data: props.data})
        // }
        props.history.push('/bibliographies')
        break
      default:
        break
    }
  }
  return (
    <>
      <Button
        variant="contained"
        aria-label='저장'
        onClick={() => handleData()}
      >저장</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="진행상황"
        disableBackdropClick={true}
      >
        <DialogTitle>서지 정보 등록 중</DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
          {circular ? <CircularProgress color="secondary"/> :
            <>
              <LinearProgress variant="determinate" color="secondary"
                              value={progress / total * 100}/>
              {progress}/{total}
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}>
            <IconCancel/>
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
