import React from 'react'
import {Show, Tab, TabbedShowLayout, TextField} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";

export default (props) => (
  <Show {...props} actions={<ShowActions/>}>

    <TabbedShowLayout>
      <Tab label="기본 정보">
        <TextField source='id'/>
        <TextField source="name"/>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
