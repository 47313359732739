import ConferenceList from './ConferenceList'
import ConferenceShow from './ConferenceShow'
import icon from '@material-ui/icons/Toc'
import ConferenceCreate from "./ConferenceCreate"
import ConferenceEdit from "./ConferenceEdit"

export default {
  create: ConferenceCreate,
  edit: ConferenceEdit,
  show: ConferenceShow,
  list: ConferenceList,
  icon: icon,

}
