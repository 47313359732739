import AuthorList from './AuthorList'
import AuthorShow from './AuthorShow'
import icon from '@material-ui/icons/Toc'
import AuthorCreate from "./AuthorCreate"
import AuthorEdit from "./AuthorEdit"

export default {
  create: AuthorCreate,
  edit: AuthorEdit,
  show: AuthorShow,
  list: AuthorList,
  icon: icon,

}
