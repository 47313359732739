import React from 'react'
import {AutocompleteInput, Edit, ReferenceInput, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="place"/>
      <TextInput source="type"/>
      <TextInput source="email"/>
      <TextInput source="phone"/>
      <TextInput source="url"/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="groupId" reference="groups">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
