import React from 'react'
import {Datagrid, Filter, List, SelectField, TextField, TextInput, UrlField} from 'react-admin'
import ListActions from "../../custom/common/ListActions";

const ListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="학술대회 검색" source="search" alwaysOn/>
      <TextInput label="타입 검색" source="type" alwaysOn/>
    </Filter>
  )
}

export default (props) => {
  return (
    <List {...props}
          exporter={false}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true}/>
        <TextField source="name" sortable={false}/>
        <TextField source="place" sortable={false}/>
        <SelectField source="type" choices={[
          {id: '국제학술', name: '국제학술'},
          {id: '국내학술', name: '국내학술'}
        ]} sortable={false}/>
        <UrlField source="url" target="_blank" sortable={false}/>
      </Datagrid>
    </List>
  )
}
