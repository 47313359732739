import BibliographyCreate from './BibliographyCreate'
import BibliographyList from './BibliographyList'
import BibliographyShow from './BibliographyShow'
import BibliographyEdit from './BibliographyEdit'
import CategoryIcon from '@material-ui/icons/Bookmark';

export default {
  create: BibliographyCreate,
  show: BibliographyShow,
  edit: BibliographyEdit,
  list: BibliographyList,
  icon: CategoryIcon,
}
