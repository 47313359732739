import BookList from './BookList'
import BookShow from './BookShow'
import icon from '@material-ui/icons/Toc'
import BookCreate from "./BookCreate"
import BookEdit from "./BookEdit"

export default {
  create: BookCreate,
  edit: BookEdit,
  show: BookShow,
  list: BookList,
  icon: icon,

}
