import AdminCreate from './AdminCreate'
import AdminList from './AdminList'
import AdminShow from './AdminShow'
import AdminEdit from './AdminEdit'
import icon from '@material-ui/icons/Toc'

export default {
  create: AdminCreate,
  edit: AdminEdit,
  show: AdminShow,
  list: AdminList,
  icon: icon,
}
