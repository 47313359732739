import {CardActions, ListButton} from 'ra-ui-materialui'
import React from 'react'

export default ({basePath}) => {
  return (
    <CardActions>
      <ListButton basePath={basePath}/>
    </CardActions>
  )
}
