import React from 'react'
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateField,
  DateInput,
  Edit,
  maxLength,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextField source='id'/>
      <SelectInput disabled source="source" choices={[
        {id: 'dblp', name: 'DBLP'},
        {id: 'ndsl', name: 'NDSL'},
        {id: 'kolis', name: 'KOLIS'},
        {id: 'kci', name: 'KCI'},
        {id: 'cau', name: 'CAU'},
        {id: 'manual', name: 'Manual'},
      ]}/>
      <TextInput source="key" validate={[required, maxLength(100)]}/>
      <TextInput source="title" validate={[required, maxLength(100)]}/>
      <TextInput source="volume"/>
      <TextInput source="year"/>
      <TextInput source="month"/>
      <TextInput target="_blank" source="url"/>
      <TextInput target="_blank" source="ee"/>
      <TextInput source="type"/>
      <TextInput source="pages"/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceArrayInput filterToQuery={(searchText) => ({search: searchText})}
                           resource='bibliography' source="authorIds" reference="authors" label='저자'>
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <ReferenceArrayInput filterToQuery={(searchText) => ({search: searchText})}
                           resource='bibliography' source="journalIds" reference="journals" label='저널'>
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <DateInput source="postedAt"/>
      <DateField source="createdAt"/>
    </SimpleForm>
  </Edit>
)
