import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  ReferenceArrayInput, AutocompleteInput, DateInput,
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="place"/>
      <TextInput source="phone"/>
      <TextInput source="email"/>
      <TextInput source="url"/>
      <SelectInput source="type" choices={[
        {id: '국제저서', name: '국제저서'},
        {id: '국내저서', name: '국내저서'}
      ]}/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="groupId" reference="groups">
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <ReferenceArrayInput filterToQuery={(searchText) => ({search: searchText})}
                           resource='books' source="authorIds" reference="authors" label='저자'>
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <DateInput source="publishedAt" showTime/>
    </SimpleForm>
  </Create>
)
