import * as XLSX from "xlsx"
import {isUndefined, omitBy} from "lodash"

export default async props => {

  const excelData = await asyncRequestExcel(props.data.files)
  console.log(excelData)
  const data = {
    conference: [],
    journal: [],
    book: [],
    person: [],
    article: [],
    bibliography: [],
    group: [],
  }

  excelData[1].forEach((row) => {
    let obj = {}
    let result = {}
    switch (row['저서']) {
      case '그룹':
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('NAME_ETC')) obj.name = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.name = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.name = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.group.push(result)
        break

      case '학술지':
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        if (row.hasOwnProperty('Source')) obj.source = row.Source
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('NAME_ETC')) obj.name = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.name = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.name = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.journal.push(result)
        break

      case '학술대회':
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        if (row.hasOwnProperty('Time')) {
          if(row.Time.split(';')[0] && row.Time.split(';')[0].length === 8) obj.startDate = row.Time.split(';')[0]
          if(row.Time.split(';')[1] && row.Time.split(';')[1].length === 8) obj.endDate = row.Time.split(';')[1]
        }
        if (row.hasOwnProperty('Person')) obj.source = row.Person
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('NAME_ETC')) obj.name = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.name = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.name = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.conference.push(result)
        break

      case '저서':
        if (row.hasOwnProperty('Time')) obj.publishedAt = row.Time

        if(row.hasOwnProperty('Person')){
          const parse = row.Person.split('저자(')
          if(parse.length === 1) obj.author = parse
          else {
            const arrayParse =  parse[1].split(')')[0]
            if((arrayParse.match(/,/g) || []).length) obj.author = arrayParse.split(',')
            else if((arrayParse.match(/;/g) || []).length) obj.author = arrayParse.split(';')
            else obj.author = [arrayParse]
          }
        }
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        if (row.hasOwnProperty('Time')) obj.createdAt = row.Time
        if (row.hasOwnProperty('Source')) obj.source = row.Source
        if (row.hasOwnProperty('NAME_ETC')) obj.name = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.name = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.name = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.book.push(result)
        break

      case '논문':
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('ID')) obj.key = row.ID
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        // if (row.hasOwnProperty('Time')) obj.createdAt = row.Time
        if (row.hasOwnProperty('Source')) obj.source = row.Source
        if (row.hasOwnProperty('NAME_ETC')) obj.title = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.title = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.title = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.bibliography.push(result)
        break

      case '기사':
        if (row.hasOwnProperty('URL')) obj.url = row.URL
        if (row.hasOwnProperty('Person')) obj.author = row.Person
        if (row.hasOwnProperty('Time')) obj.postedAt = row.Time
        if (row.hasOwnProperty('Source')) obj.source = row.Source
        if (row.hasOwnProperty('NAME_ETC')) obj.title = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.title = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.title = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.article.push(result)
        break

      case '인물':
        if (row.hasOwnProperty('Source')) obj.source = row.Source
        if (row.hasOwnProperty('Type')) obj.type = row.Type
        if (row.hasOwnProperty('Place')) obj.place = row.Place
        if (row.hasOwnProperty('ID')) obj.personId = row.ID
        if (row.hasOwnProperty('NAME_ETC')) obj.name = row['NAME_ETC']
        if (row.hasOwnProperty('NAME_EN')) obj.name = row['NAME_EN']
        if (row.hasOwnProperty('NAME_KO')) obj.name = row['NAME_KO']
        result = omitBy(obj, isUndefined)
        data.person.push(result)
        break

      default:
        break
    }
  })
  console.log(data)
  return data

}


export const asyncRequestExcel = (excel) => {
  return new Promise(function (resolve, reject) {
    const excelUrl = excel.src
    const oReq = new XMLHttpRequest();
    oReq.open("GET", excelUrl, true);
    oReq.responseType = "arraybuffer";
    oReq.onload = function (e) {
      const arrayBuffer = oReq.response;
      /* convert data to binary string */
      const data = new Uint8Array(arrayBuffer);

      const arr = []
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }

      const bstr = arr.join("");
      const cfb = XLSX.read(bstr, {type: 'binary'});

      const parsing = cfb.SheetNames.map(function (sheetName, index) {
        // Obtain The Current Row As CSV
        return XLSX.utils.sheet_to_json(cfb.Sheets[sheetName]);
      })
      resolve(parsing)
    }
    oReq.send()
  })
}
