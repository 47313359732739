import React from 'react'
import {Create, SimpleForm, TextInput, SelectInput, AutocompleteInput, ReferenceInput,} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="personId"/>
      <TextInput source="phone"/>
      <TextInput source="email"/>
      <SelectInput source="type" choices={[
        {id: '연구자', name: '연구자'},
        {id: '기자', name: '기자'},
        {id: '일반', name: '일반'},
      ]}/>
      <TextInput source="place"/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
