import JournalList from './JournalList'
import JournalShow from './JournalShow'
import icon from '@material-ui/icons/Toc'
import JournalEdit from "./JournalEdit"
import JournalCreate from "./JournalCreate"

export default {
  edit: JournalEdit,
  create: JournalCreate,
  show: JournalShow,
  list: JournalList,
  icon: icon,

}
