import React from 'react'
import {connect} from 'react-redux'
import {Layout, Sidebar} from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'

const CustomSidebar = props => <Sidebar size={200} closedSize={150} {...props} />
const CustomLayout = props => (
  <Layout {...props}
          appBar={AppBar}
          menu={Menu}
          sidebar={CustomSidebar} />
)

const theme = {
  palette: {
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
  },
}

export default connect(
  state => ({
    theme
  }),
  {}
)(CustomLayout)
