import React from 'react'
import {AutocompleteInput, Create, ReferenceInput, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="place"/>
      <TextInput source="email"/>
      <TextInput source="phone"/>
      <TextInput source='url'/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
