import GroupList from './GroupList'
import GroupShow from './GroupShow'
import icon from '@material-ui/icons/Toc'
import GroupCreate from "./GroupCreate"
import GroupEdit from "./GroupEdit"

export default {
  create: GroupCreate,
  edit: GroupEdit,
  show: GroupShow,
  list: GroupList,
  icon: icon,

}
