import React from 'react'
import Login from './Login'
import {ThemeProvider} from '@material-ui/styles'
import {createMuiTheme} from '@material-ui/core/styles'
import {lightTheme} from '../themes'

const Auth = (props) => {

  return (
      <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props}/>
      </ThemeProvider>
  )
}

export default Auth
