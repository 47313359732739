import {EditButton, ListButton, TopToolbar} from 'ra-ui-materialui'
import React from 'react'

export default ({basePath, data}) => {
  const permission = localStorage.getItem('role')
  return (
    <TopToolbar>
      <ListButton basePath={basePath}/>
      {permission !== 'guest' && <EditButton basePath={basePath} record={data}/> }
    </TopToolbar>)
}
