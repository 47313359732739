import React from 'react'
import {DateField, ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin'
import ShowActions from "../../custom/common/ShowActions";

export default (props) => (
  <Show {...props} actions={<ShowActions/>}>

    <TabbedShowLayout>
      <Tab label="기본 정보">
        <TextField source='id'/>
        <TextField source='title'/>
        <TextField source="url"/>
        <TextField source="createdAt"/>
        <ReferenceField source="groupId" reference="groups" allowEmpty linkType={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="authorId" reference="authors" allowEmpty linkType={'show'}>
          <TextField source="name"/>
        </ReferenceField>
        <DateField source="createdAt" showTime/>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
