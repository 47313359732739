import React from 'react'
import {Datagrid, DateField, Filter, List, TextField, TextInput, UrlField} from 'react-admin'
import ListActions from "../../custom/common/ListActions";

const ListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="기사 검색" source="search" alwaysOn/>
    </Filter>
  )
}

export default (props) => {
  return (
    <List {...props}
          exporter={false}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true}/>
        <TextField source='title' sortable={false}/>
        <UrlField source="url" sortable={false}/>
        <DateField source="postedAt" sortable={false}/>
      </Datagrid>
    </List>
  )
}
