export const searchField = [
  {id: 'total_field', name: '전체'},
  {id: 'title', name: '제목'},
  {id: 'author', name: '저자'},
  {id: 'publisher', name: '발행자/대학명'},
]
export const displayCount= [
  {id: 10, name: '10'},
  {id: 20, name: '20'},
  {id: 50, name: '50'},
  {id: 100, name: '100'},
]

export const sortNm= [
  {id: 'title', name: '제목'},
  {id: 'author', name: '저자'},
  {id: 'pubiYr', name: '발행일자'},
]

export const sortDir= [
  {id: 'asc', name: '오름차순'},
  {id: 'desc', name: '내림차순'},
]
