import React from 'react'
import {Datagrid, DateField, List, SelectField, TextField} from 'react-admin'
import ListActions from "../../custom/common/ListActions";

export default (props) => {
  return (
    <List {...props}
          exporter={false}
          actions={<ListActions/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <TextField source="name"/>
        <SelectField source="role" choices={[
          {id: 'master', name: '관리자'},
          {id: 'manager', name: '작업자'},
          {id: 'guest', name: '손님'}
        ]}/>
        <DateField source="createdAt" locales='ko'/>
        <DateField source="updatedAt" locales='ko'/>
      </Datagrid>
    </List>
  )
}
