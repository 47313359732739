import React, {useState} from 'react'
import {Datagrid, Filter, List, TextField, TextInput, UrlField} from 'react-admin'
import {useHistory} from 'react-router'

export default (props) => {
  const [source, setSource] = useState('bibliographies')
  const history = useHistory()
  const ListFilter = props => {
    return (
      <Filter {...props}>
        <TextInput label="검색" source="search" alwaysOn/>
        {/*<SelectInput label='타입' source="source" onChange={onSourceChange} choices={[*/}
        {/*  {id: 'Bibliographies', name: '논문'},*/}
        {/*  {id: 'Groups', name: '그룹'},*/}
        {/*  {id: 'Authors', name: '저자'},*/}
        {/*  {id: 'Journals', name: '학술지'},*/}
        {/*  {id: 'Books', name: '저서'},*/}
        {/*  {id: 'Articles', name: '기사'},*/}
        {/*  {id: 'Conferences', name: '학술대회'},*/}
        {/*  {id: 'Keywords', name: '키워드'},*/}
        {/*]} alwaysOn/>*/}
      </Filter>
    )
  }

  return (
    <List {...props}
          exporter={false}
          filterDefaultValues={{source: 'Bibliographies'}}
          filters={<ListFilter/>}
          bulkActionButtons={false}>
      <Datagrid rowClick={(record, resource, row) => history.push(`/${row.source.toLowerCase()}/${row.id}/show`)}>
        <TextField source='id' sortable={true}/>
        <TextField source="name" sortable={false}/>
        <TextField source="place" sortable={false}/>
        <UrlField source='url' target="_blank" sortable={false}/>
      </Datagrid>
    </List>
  )
}
