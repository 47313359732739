import React from 'react'
import {Create, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, DateInput} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source='title' validate={required}/>
      <TextInput source="url"/>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="groupId" reference="groups">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="authorId" reference="authors">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput filterToQuery={(searchText) => ({search: searchText})}
                      source="keywordId" reference="keywords">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <DateInput source="postedAt"/>
    </SimpleForm>
  </Create>
)
